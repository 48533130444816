export const productGridCarouselMixin = {
  computed: {
    itemCount() {
      return (this.items && this.items.length + this.extraCardCount) || 0
    },
    pageCount() {
      return Math.min(this.maxPages, Math.ceil(this.itemCount / this.perPage))
    },
    activeItems() {
      return this.items && this.items.slice(0, this.perPage * this.maxPages)
    },
  },
  methods: {
    previousPage() {
      this.scrollToPage(this.page - 1)
    },
    nextPage() {
      this.scrollToPage(this.page + 1)
    },
    scrollToPage(page) {
      try {
        // assumes div#scrollGrid -> ul -> li, update if that changes
        const items = this.$refs.scrollGrid.children[0].children
        const item = items[(page - 1) * this.perPage]
        this.$refs.scrollGrid.scrollLeft = item.offsetLeft
      } catch (err) {
        // do nothing
      }
    },
    updatePage() {
      if (!this.$refs.scrollGrid) {
        this.page = 1
        return
      }
      const itemWidth =
        this.$refs.scrollGrid.scrollWidth / Math.max(1, this.itemCount)
      const pageWidth = itemWidth * this.perPage
      // Round to the nearest 10th (2.3) and then round up
      // Adjusts for slight discrepancies in scroll distance when pages are
      // uneven
      this.page =
        Math.ceil(
          Math.trunc((10 * this.$refs.scrollGrid.scrollLeft) / pageWidth) / 10
        ) + 1
    },
    productClicked(product) {
      this.$store.dispatch('setCarouselInView', this.carouselId)
      this.$emit('detail-clicked', product)
    },
    addClicked() {
      this.$emit('add-clicked')
    },
    addToCart(data) {
      this.$emit('add-to-cart', data)
    },
  },
}
