<template>
  <div class="wa-product-carousel" :data-carousel-id="carouselId">
    <h4 v-if="title">{{ title }}</h4>
    <div>
      <button v-show="page !== 1" class="prev" @click="previousPage">
        Prev
      </button>
      <div ref="scrollGrid" class="carousel-scroll-grid" @scroll="updatePage">
        <ul
          :class="{
            'product-grid': true,
            'product-grid-centered': itemCount < perPage,
          }"
        >
          <NarrativeProductCard
            v-for="item in activeItems"
            :key="item.id"
            :product="item"
            :add-button-text="addButtonText"
            :extra-add-to-cart-data="extraAddToCartData"
            allow-add-to-cart
            allow-deeper-discount
            allow-category-label
            @detail-clicked="productClicked"
            @add-clicked="addClicked"
            @add-to-cart="addToCart"
          />
          <slot name="extraCards"></slot>
        </ul>
      </div>
      <button v-show="page !== pageCount" class="next" @click="nextPage">
        Next
      </button>
    </div>
    <div class="carousel-pager">
      <ul class="pagination">
        <li
          v-for="index in pageCount"
          :key="index"
          :class="{ current: index === page }"
        >
          <span v-if="index === page" class="show-for-sr">You're on page</span>
          <button @click="scrollToPage(index)">{{ index }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { productGridCarouselMixin } from '~/mixins/productGridCarouselMixin'
export default {
  components: {
    NarrativeProductCard: () => import('./Card/NarrativeProductCard'),
  },
  mixins: [productGridCarouselMixin],
  props: {
    carouselId: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'You might also like these wines',
    },
    extraCardCount: {
      type: Number,
      default: 0,
    },
    addButtonText: {
      type: String,
      default: 'Add to Cart',
    },
    extraAddToCartData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      page: 1,
      perPage: 3,
      maxPages: 12,
    }
  },
  watch: {
    activeItems: {
      handler(to) {
        this.scrollToPage(1)
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.adjustCarouselSize)
    this.adjustCarouselSize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustCarouselSize)
  },
  methods: {
    adjustCarouselSize() {
      if (process.client) {
        const width = window.innerWidth
        if (width <= 769) {
          this.perPage = 1
        } else if (width <= 1440) {
          this.perPage = 2
        } else {
          this.perPage = 3
        }
      }
    },
  },
}
</script>
